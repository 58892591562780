(function(window) {
		const { document } = window
		const jsCookies = {}

		jsCookies.get = function (cookieName) {
			if (document.cookie.length > 0) {
				let cookieStart = document.cookie.indexOf(`${cookieName}=`)
				if (cookieStart != -1) {
					cookieStart = cookieStart + cookieName.length + 1
					let cookieEnd = document.cookie.indexOf(";", cookieStart)
					if (cookieEnd == -1) {
						cookieEnd = document.cookie.length
					}
					return unescape(document.cookie.substring(cookieStart, cookieEnd))
				}
			}
		
			return ''
		}
		
		jsCookies.set = function (name, value, expireDays, path, domain, secure) {
			let expireDate = null
			let expireString = ''
		
			if (expireDays != null) {
				expireDate = new Date()
				expireDate.setDate(expireDate.getDate() + expireDays)
				expireString = `; expires=${expireDate.toUTCString()}`
			}
		
			const nameString = `${name}=${escape(value)}`
			const pathString = (typeof path === 'undefined') ? '' : `; path=${path}`
			const domainString = (typeof domain === 'undefined') ? '' : `; domain=${domain}`
			const secureString = (secure) ? '; secure' : ''
			
			document.cookie = `${nameString}${expireString}${pathString}${domainString}${secureString}`
		}
		
		jsCookies.check = function (cookieName) {
			const cookieValue = jsCookies.get(cookieName)
			if (cookieValue !== null && cookieValue !== "") {
				return true
			} 
			
			return false		
		}		

		window.jsCookies = jsCookies
		module.exports = jsCookies

}(window))
